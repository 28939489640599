import { useQuery } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { Body, Dialog, DialogContent, Icon, Stack } from '@katoa/anatomy'
import { mdiArrowDownDropCircle, mdiCheck, mdiHospitalBuilding } from '@mdi/js'
import { type FC, type MouseEvent, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Paths } from '../../../config/app'
import { GET_USER_FACILITY_CONFIGURATIONS } from '../../../graphql/queries/get-user-facility-configurations'
import type {
  UserFacilityConfigurations,
  UserFacilityConfigurationsVariables,
} from '../../../graphql/type/UserFacilityConfigurations'

export interface Facility {
  id: string
  name: string
}

export const FacilityPicker: FC = () => {
  const [isFacilityPickerEnabled, setIsFacilityPickerEnabled] = useState(false)

  const { pathname } = useLocation()

  const { facilityId } = useParams()

  const { data } = useQuery<UserFacilityConfigurations, UserFacilityConfigurationsVariables>(
    GET_USER_FACILITY_CONFIGURATIONS,
    {
      variables: {
        facilityByIdInput: {
          facilityId: facilityId || '',
        },
      },
      skip: facilityId === 'alerts',
    },
  )

  const facilityConfigurations = data?.clinicianUser?.facilityConfigurations
  const currentFacility = data?.facilityById

  if (!facilityConfigurations) {
    return null
  }

  const sortedFacilityConfigurations = [...facilityConfigurations].sort((a, b) => {
    return a.facility.name.localeCompare(b.facility.name)
  })

  const handleClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    const newFacilityId = event.currentTarget.dataset.facilityId

    if (facilityId !== newFacilityId) {
      datadogRum.addAction('change Facility')

      setIsFacilityPickerEnabled(false)
    }
  }

  if (sortedFacilityConfigurations.length === 1) {
    return (
      <a href={Paths.Alerts} className="flex flex-nowrap items-center gap-2">
        <Icon path={mdiHospitalBuilding} size={3} colour="stone" />

        <Body asChild={true} data-testid="global-header:facility-name" data-dd-action-name="facility picker">
          <h1>
            <strong>{currentFacility?.name || 'Select a facility'}</strong>
          </h1>
        </Body>
      </a>
    )
  }

  return (
    <>
      <button
        className="flex w-full cursor-pointer flex-nowrap items-center gap-3 rounded border-0 bg-dust px-4 py-3"
        type="button"
        onClick={(): void => {
          setIsFacilityPickerEnabled(true)
        }}
        data-dd-action-name="facility picker"
      >
        <Icon path={mdiHospitalBuilding} size={3} colour="stone" />

        <Body asChild={true} data-testid="global-header:facility-name">
          <h1>
            <strong>{currentFacility?.name || 'Select a facility'}</strong>
          </h1>
        </Body>

        <Icon className="ms-auto" path={mdiArrowDownDropCircle} size={3} colour="stone" />
      </button>
      <Dialog
        size="piwakawaka"
        title="Select a Facility"
        isVisible={isFacilityPickerEnabled}
        onRequestDismiss={(): void => {
          setIsFacilityPickerEnabled(false)
        }}
      >
        <DialogContent>
          <Stack spacing={1}>
            {sortedFacilityConfigurations?.map((facilityConfiguration) => {
              const { facility } = facilityConfiguration
              const isCurrentFacility = facilityId === facility.id

              const newPathname = pathname
                .replace(facilityId || '', facility.id)
                // navigate away from facility specific visits and alerts
                .replace(/\/visits\/.+/, '/visits')
                .replace(/\/alerts\/.+/, '/alerts')

              return (
                <Link
                  className={twMerge(
                    'flex w-full gap-3 rounded border-0 p-3 no-underline',
                    'hover:bg-dust',
                    isCurrentFacility && 'cursor-default hover:bg-transparent',
                  )}
                  data-facility-id={facility.id}
                  key={facility.id}
                  to={newPathname}
                  onClick={handleClick}
                >
                  <Icon path={isCurrentFacility ? mdiCheck : mdiHospitalBuilding} size={3} colour="stone" />
                  <Body>{facility.name}</Body>
                </Link>
              )
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
