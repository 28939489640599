import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface CardGutterProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode
}

export const CardGutter: FC<CardGutterProps> = ({ children, className, ...rest }) => {
  return (
    <div className={twMerge('px-4 py-6', className)} {...rest}>
      {children}
    </div>
  )
}
