import type { FC, ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import type { Size } from '../../types'
import { Body } from '../typography/body'

export interface TagProps {
  children: string
  className?: string
  size?: Extract<Size, 'piwakawaka' | 'kiwi'>
  theme?: 'dark' | 'light' | 'outline' | 'facility' | 'gray'
  elevated?: boolean
}

export const Tag: FC<TagProps> = ({
  children,
  className,
  size = 'piwakawaka',
  theme = 'light',
  elevated = false,
  ...attributes
}): ReactElement => {
  return (
    <Body
      className={twMerge(
        'w-fit rounded-md uppercase leading-none text-inherit',
        size === 'kiwi' && 'p-2 text-sm font-bold uppercase tracking-[1.5px]',
        size === 'piwakawaka' && 'p-1 px-2 text-xs font-semibold uppercase tracking-[1.5px]',
        theme === 'light' && 'bg-dust text-storm',
        theme === 'dark' && 'bg-midnight text-white',
        theme === 'outline' && 'border text-storm',
        theme === 'facility' && 'bg-primary-background text-primary-text',
        theme === 'gray' && 'bg-stone text-white',
        elevated === true && 'shadow-md',
        className,
      )}
      {...attributes}
    >
      {children}
    </Body>
  )
}
