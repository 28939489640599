import { Sex } from '../graphql/global-types'

/**
 * Takes the long form of Sex values and returns the shorthand acronym for each value possible from the EHR.
 * @param sex - The long form of Sex values, defined by the GraphQL type of "Sex". e.g. "Female"
 * @returns Returns the acronym of each of our mapped sex values, or null if the value is null or undefined. e.g "F"
 **/
export const displaySexAcronym = (sex: Sex | null | undefined): string | null => {
  switch (sex) {
    case Sex.Female:
      return 'F'
    case Sex.Male:
      return 'M'
    case Sex.Nonbinary:
      return 'NB'
    case Sex.Other:
      return 'O'
    case Sex.Unknown:
      return 'U'
    case Sex.X:
      return 'X'
    default:
      return null
  }
}
