import { Icon, Subheading } from '@katoa/anatomy'
import { mdiMenuDown, mdiMenuUp } from '@mdi/js'
import type { ComponentPropsWithoutRef, FC, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

const ListView: FC<ComponentPropsWithoutRef<'div'>> = ({ children, className, ...props }) => {
  return (
    <div className={twMerge(className)} {...props}>
      {children}
    </div>
  )
}

const ListViewItem: FC<ComponentPropsWithoutRef<'div'>> = ({ children, className, ...props }) => {
  return (
    <div
      className={twMerge(
        'relative grid w-full gap-3 bg-white p-3',
        '[&+&]:border-t',
        '[&>*]:flex [&>*]:items-center',
        '[&>*:last-child]:justify-end [&>*:last-child]:text-right',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const ListViewHeader: FC<ComponentPropsWithoutRef<'header'>> = ({ children, className, ...props }) => {
  return (
    <header
      className={twMerge(
        'sticky top-0 z-10 grid gap-3 border-b bg-dust p-4',
        '[&>*:last-child]:justify-end [&>*:last-child]:text-right',
        className,
      )}
      {...props}
    >
      {children}
    </header>
  )
}

export interface ListViewHeaderSortableProps extends ComponentPropsWithoutRef<'button'> {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  sortDirection?: 'ascending' | 'descending'
}

const ListViewHeaderSortable: FC<ListViewHeaderSortableProps> = ({ sortDirection, onClick, children, ...props }) => {
  const sortArrow = sortDirection === 'ascending' ? mdiMenuDown : mdiMenuUp

  return (
    <button
      className="flex items-center hover:opacity-60 active:opacity-100"
      type="button"
      onClick={onClick}
      {...props}
      data-test-sort-header={sortDirection}
    >
      <Subheading>{children}</Subheading>
      {sortDirection && <Icon path={sortArrow} size={2} />}
    </button>
  )
}

export default ListView
export { ListViewHeader, ListViewItem, ListViewHeaderSortable }
