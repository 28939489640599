/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnswerType {
  LongText = "LongText",
  MultipleChoice = "MultipleChoice",
  Rating = "Rating",
}

export enum ErVisitStepKey {
  ADMIT = "ADMIT",
  DISCHARGE = "DISCHARGE",
  DISPO = "DISPO",
  OBSERVATION = "OBSERVATION",
  OUTCOME = "OUTCOME",
  SECONDARY_ASSESSMENT = "SECONDARY_ASSESSMENT",
  TRANSFER = "TRANSFER",
  TREATMENT_AREA = "TREATMENT_AREA",
  TRIAGE = "TRIAGE",
}

export enum ErVisitStepStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum NotificationType {
  SurveyResponse = "SurveyResponse",
}

export enum PatientClass {
  Emergency = "Emergency",
  Inpatient = "Inpatient",
}

export enum Relationship {
  CAREGIVER = "CAREGIVER",
  CHILD = "CHILD",
  FRIEND = "FRIEND",
  OTHER = "OTHER",
  SELF = "SELF",
  SPOUSE = "SPOUSE",
}

export enum Sex {
  Female = "Female",
  Male = "Male",
  Nonbinary = "Nonbinary",
  Other = "Other",
  Unknown = "Unknown",
  X = "X",
}

export enum VisitType {
  er = "er",
  inpatient = "inpatient",
  unknown = "unknown",
}

export interface AddClinicianUserPhoneNumberInput {
  email: string;
  facilityId?: string | null;
  phoneNumber: string;
}

export interface AssignNotificationInput {
  id: string;
  email?: string | null;
  facilityId?: string | null;
}

export interface ClinicianUserFacilityConfigurationInput {
  facilityId: string;
}

export interface ClinicianUserFacilityConfigurationsForFacilityIdInput {
  facilityId: string;
}

export interface ClinicianUserFilters {
  locationIds?: string[] | null;
  notificationGroupIds?: string[] | null;
}

export interface CompleteNotificationInput {
  isCompleted?: boolean | null;
  id: string;
  facilityId?: string | null;
}

export interface CreateNotificationOnBehalfOfPatientInput {
  visitId: string;
  notificationGroupId: string;
  requestId: string;
  requestDetails?: string | null;
  facilityId?: string | null;
}

export interface CurrentNotificationsInput {
  facilityId?: string | null;
}

export interface DisableVisitInput {
  visitId: string;
  facilityId?: string | null;
}

export interface DuplicateVisitInput {
  visitId: string;
  facilityId?: string | null;
}

export interface FacilityByIdInput {
  facilityId: string;
}

export interface FilteredClinicianUsersInput {
  filters?: ClinicianUserFilters | null;
  facilityId?: string | null;
}

export interface FilteredCurrentVisitsInput {
  filters?: VisitFilter[] | null;
  facilityId?: string | null;
}

export interface NotificationByIdForFacilityIdInput {
  notificationId: string;
  facilityId?: string | null;
}

export interface PatientInviteInput {
  visitId: string;
  facilityId?: string | null;
}

export interface RetrieveClinicianAuthTokensInput {
  code: string;
  redirectUri: string;
}

export interface SMSMessageInput {
  visitId: string;
  message: string;
  facilityId?: string | null;
}

export interface ToggleClinicianUserNotificationsInput {
  email: string;
  active: boolean;
  facilityId: string;
}

export interface UpdateClinicianUserLocationsInput {
  locationIds: string[];
  facilityId: string;
}

export interface UpdateClinicianUserNotificationGroupsInput {
  notificationGroupIds: string[];
  facilityId: string;
}

export interface UpsertVisitPhoneNumberInput {
  visitId: string;
  phoneNumber: string;
  isPrimary: boolean;
  relationship: Relationship;
  name?: string | null;
  facilityId?: string | null;
}

export interface VisitByIdForFacilityIdInput {
  visitId: string;
  facilityId?: string | null;
}

export interface VisitFilter {
  patientClass?: PatientClass | null;
  departmentRegex?: string | null;
  roomRegex?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
