import { AnimatePresence, m } from 'framer-motion'
import {
  Children,
  type ComponentPropsWithoutRef,
  type FC,
  type KeyboardEvent,
  type ReactNode,
  isValidElement,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { twMerge } from 'tailwind-merge'
import { LazyMotion } from '../lazy-motion/lazy-motion'
import { Status } from '../status/status'

export interface DrawerProps
  extends Omit<ComponentPropsWithoutRef<'div'>, keyof ComponentPropsWithoutRef<typeof m.div>> {
  isVisible?: boolean
  killAnimation?: boolean
  children?: ReactNode
  loading?: boolean
  className?: string
}

const variants = {
  slideLeft: {
    x: '100%',
    opacity: 0.8,
  },
  onCanvas: {
    x: 0,
    opacity: 1,
  },
}

export const Drawer: FC<DrawerProps> = ({
  children,
  isVisible,
  killAnimation = false,
  loading,
  className,
  ...attributes
}) => {
  const portalRoot = useRef(document.getElementById('portal'))
  const [visible, setVisible] = useState(isVisible)

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape') {
      setVisible(false)
    }
  }

  if (portalRoot.current) {
    return createPortal(
      <LazyMotion>
        <AnimatePresence>
          {visible && (
            <FocusLock returnFocus={true} disabled={false} persistentFocus={false}>
              <m.div
                variants={killAnimation ? {} : variants}
                initial="slideLeft"
                animate="onCanvas"
                exit="slideLeft"
                transition={{ type: 'spring', stiffness: 500, damping: 40 }}
                className={twMerge(
                  'fixed inset-0 z-50 flex w-full max-w-none flex-col overflow-y-auto overflow-x-hidden bg-[var(--colour-background)]',
                  '[--header-button-colour:var(--colour-brand-white)]',
                  '[--gutter-padding:var(--spacing-5)]',
                  '[--colour-background:var(--colour-brand-white)]',
                  '[--header-text-colour:var(--colour-brand-white)]',
                  '[--header-background-colour:var(--colour-brand-midnight)]',
                  'md:left-auto md:max-w-xs md:shadow-lg',
                  'md:[--header-button-colour:var(--colour-brand-midnight)]',
                  'md:[--header-text-colour:var(--interface-text)]',
                  'md:[--header-background-colour:var(--colour-background)]',
                  'lg:max-w-xs',
                  'xl:max-w-md',
                  className,
                )}
                // biome-ignore lint/a11y/useSemanticElements:reason
                role="dialog"
                aria-modal="true"
                data-testid="component:drawer"
                data-loading={loading}
                onKeyDown={handleKeyDown}
                {...attributes}
              >
                {loading && <Status size="moa" status="loading" className="m-auto" />}
                {!loading && Children.toArray(children).filter(isValidElement)}
              </m.div>
            </FocusLock>
          )}
        </AnimatePresence>
      </LazyMotion>,
      portalRoot.current,
    )
  }

  return null
}
