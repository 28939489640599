import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface SkeletonLoaderProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
}

export const SkeletonLoader: FC<SkeletonLoaderProps> = ({ children, className = '' }) => {
  return (
    <div className={twMerge('animate-pulse bg-slate-100', className)} data-testid="component:skeleton-loader">
      {children}
    </div>
  )
}
