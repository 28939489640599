import { mdiArrowLeft } from '@mdi/js'

import type { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../button/button'
import { Subheading } from '../typography/subheading'

export interface DrawerHeaderProps {
  title: string
  backTo: string
}

export const DrawerHeader: FC<DrawerHeaderProps> = ({ backTo, title }) => {
  const [searchParams] = useSearchParams()

  return (
    <header
      data-dd-privacy="mask"
      className="sticky top-0 z-20 shrink-0 grow-0 basis-0 bg-[var(--header-background-colour)] px-[var(--gutter-padding)] pb-6 pt-10 leading-[1.2] text-[var(--header-text-colour)]"
      data-testid="component:drawer:header"
      data-dd-action-name="drawer header"
    >
      <div className="flex flex-nowrap items-center justify-between gap-0">
        <Button
          className="self-start text-white ![--colour-button-background:var(--header-button-colour)] focus:outline-none focus-visible:ring md:text-primary-background"
          to={{ pathname: backTo, search: searchParams.toString() }}
          element="link"
          intent="positive"
          collapsed={true}
          iconAfter={mdiArrowLeft}
          aria-label="Back"
          data-testid="back-via-button"
        />
        <Subheading asChild={true} tabIndex={0} className="text-center outline-0">
          <h1 data-dd-privacy="mask" data-dd-action-name="drawer subheading title">
            {title}
          </h1>
        </Subheading>

        <div tabIndex={-1} className="h-[1.5em] w-[1.5em] shrink-0 grow-0 basis-auto" />
      </div>
    </header>
  )
}
