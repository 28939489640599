export type FormatPhoneNumberParms = (phoneNumber?: string | null, showCountry?: boolean) => string | null

export const formatPhoneNumber: FormatPhoneNumberParms = (phoneNumber, showCountryCode = true) => {
  if (!phoneNumber) {
    return null
  }

  // Matches a valid US number
  const usCountryCode = '+1'

  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '')

  const match = phoneNumber.startsWith(usCountryCode) && cleanedPhoneNumber.match(/^1(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    if (showCountryCode) {
      return `${usCountryCode} (${match[1]}) ${match[2]}-${match[3]}`
    }

    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return phoneNumber
}
