import type { ComponentPropsWithoutRef, FC } from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

interface DivProps extends ComponentPropsWithoutRef<'div'> {
  to?: undefined
}

export type MaybeLinkProps = LinkProps | DivProps

const MaybeLink: FC<MaybeLinkProps> = (props) => {
  const { to } = props

  if (to !== undefined) {
    return <Link {...props} />
  }

  return <div {...props} />
}

export default MaybeLink
