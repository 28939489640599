import { type MouseEvent, useEffect, useState } from 'react'
import type { SortableColumnKey, SortableDirection } from './sorting'

export const useSortColumn = (isBedAutomatic: boolean) => {
  // Figure out how to sort the rows
  const [sortByColumn, setSortByColumn] = useState<SortableColumnKey>(isBedAutomatic ? 'location' : 'patient')
  const [sortDirection, setSortDirection] = useState<SortableDirection>('ascending')

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const newSortByColumn = isBedAutomatic ? 'location' : 'patient'

    if (newSortByColumn !== sortByColumn) {
      setSortByColumn(newSortByColumn)
    }
  }, [isBedAutomatic])

  const onSort = (event: MouseEvent<HTMLButtonElement>): void => {
    const columnName = (event?.currentTarget?.dataset?.columnName as SortableColumnKey) || 'patient'

    if (sortByColumn === columnName) {
      // If the user clicks the same header more than twice in a row, toggle the direction values.
      setSortDirection(sortDirection === 'descending' ? 'ascending' : 'descending')
    } else {
      // Default to ascending.
      setSortDirection('ascending')
      setSortByColumn(columnName)
    }
  }

  return { sortByColumn, sortDirection, onSort }
}
