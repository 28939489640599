import type { FilteredCurrentVisits_filteredCurrentVisits_demographics as Demographics } from '../graphql/type/FilteredCurrentVisits'

export const isUpperCase = (string: string): boolean => string === string.toUpperCase()
export const toTitleCase = (string: string): string =>
  string.replace(/\b\w+/g, (substring) => substring.charAt(0).toUpperCase() + substring.substr(1).toLowerCase())

function convertUppercaseNameToTitleCase(name: string | null): string | null {
  if (name === null) {
    return null
  }

  if (!isUpperCase(name)) {
    return name
  }

  return toTitleCase(name)
}

/**
 * Given a demographics object with null, firstName AND OR lastName, return a formatted name.
 * If the given name is ALL_CAPS, it is Title Cased instead.
 * We don't transform non-ALL_CAPS names though!
 */
export function formatName(demographics: Pick<Demographics, 'firstName' | 'lastName'> | null): string {
  const firstName = convertUppercaseNameToTitleCase(demographics?.firstName ?? null)
  const lastName = convertUppercaseNameToTitleCase(demographics?.lastName ?? null)

  if (lastName && firstName) {
    return `${lastName}, ${firstName}`
  }

  if (lastName) {
    return lastName
  }

  if (firstName) {
    return firstName
  }

  // Defaults to an anonymous patient string
  return 'Anonymous patient'
}
