import { useQuery } from '@apollo/client'
import { type FC, type ReactNode, createContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import DelayedLoader from '../../app/ui-components/delayed-loader/delayed-loader'
import GET_FACILITY_BASE_QUERY from '../../graphql/queries/get-facility-base'
import type { FacilityBase } from '../../graphql/type/FacilityBase'
import { NoFacilityPage } from '../../pages/no-facility/no-facility'

export interface FacilityProviderProps {
  children?: ReactNode
}

interface EnabledFeatures {
  providerPatientMessaging: boolean
  serviceRequests: boolean
  reasonForVisit: boolean
}

interface FacilityBaseConfig {
  id: string | null
  name: string | null
  enabledFeatures: EnabledFeatures
  deployFlags: Record<string, boolean>
}

/**
 * The enabled features and deploy flags can all 'default' to false here.
 * The 'fallback' logic is handled on the server
 * (where some of these are set to true *or* false if they aren't overriden on the facility itself).
 * We don't want to duplicate that logic on the frontend.
 */
const INITIAL_VALUE: FacilityBaseConfig = {
  id: null,
  name: 'Vital Response',
  enabledFeatures: {
    providerPatientMessaging: false,
    serviceRequests: false,
    reasonForVisit: false,
  },
  deployFlags: {},
}

export const FacilityContext = createContext(INITIAL_VALUE)
FacilityContext.displayName = 'FacilityContext'

const FacilityProvider: FC<FacilityProviderProps> = ({ children }) => {
  const { facilityId } = useParams()

  const { error, loading, data } = useQuery<FacilityBase>(GET_FACILITY_BASE_QUERY, {
    variables: {
      input: {
        facilityId,
      },
    },
    skip: facilityId === 'alerts',
  })

  const facility = data?.facilityById

  const id = facility?.id ?? INITIAL_VALUE.id
  const name = facility?.name ?? INITIAL_VALUE.name
  const enabledFeatures = useMemo(
    () => ({
      providerPatientMessaging:
        facility?.enabledFeatures?.providerPatientMessaging ?? INITIAL_VALUE.enabledFeatures.providerPatientMessaging,
      serviceRequests: facility?.enabledFeatures?.serviceRequests ?? INITIAL_VALUE.enabledFeatures.serviceRequests,
      reasonForVisit: facility?.enabledFeatures?.reasonForVisit ?? INITIAL_VALUE.enabledFeatures.reasonForVisit,
    }),
    [facility?.enabledFeatures],
  )
  const deployFlags = useMemo(() => ({}), [])

  const contextValue = useMemo(
    () => ({
      id,
      name,
      enabledFeatures,
      deployFlags,
    }),
    [id, name, enabledFeatures, deployFlags],
  )

  // If loading facility info, display some form of loader to prevent layout re-builds/content flashing.
  if (loading) {
    return <DelayedLoader />
  }

  // If the GraphQL query fails, then load using sensible defaults.
  if (error) {
    return <FacilityContext.Provider value={INITIAL_VALUE}>{children}</FacilityContext.Provider>
  }

  if (facility === null) {
    return <NoFacilityPage />
  }

  return <FacilityContext.Provider value={contextValue}>{children}</FacilityContext.Provider>
}

export default FacilityProvider
