import { gql } from '@apollo/client'

const SEND_SMS_MESSAGE_MUTATION = gql`
  mutation SendSMSMessage($input: SMSMessageInput!) {
    sendSMSMessage(input: $input) {
      success
    }
  }
`

export default SEND_SMS_MESSAGE_MUTATION
