import { Body, Stack, Tag } from '@katoa/anatomy'
import type { FC } from 'react'

interface VisitSubpageLocationTagProps {
  assignedBed: string | null
  assignedRoom: string | null
  currentBed: string | null
  currentRoom: string | null
}
const VisitSubpageLocationTag: FC<VisitSubpageLocationTagProps> = ({
  assignedBed,
  assignedRoom,
  currentBed,
  currentRoom,
}) => {
  const isLocationMatch = !!(currentRoom === assignedRoom && currentBed === assignedBed)
  const noKnownLocation = !currentRoom && !currentBed && !assignedRoom && !assignedBed

  const hasAssignedLocation = assignedRoom || assignedBed
  const hasCurrentLocation = currentRoom || currentBed

  return (
    <div>
      {noKnownLocation && (
        <Body asChild={true} className="text-stone">
          <div>Not Available</div>
        </Body>
      )}
      {!isLocationMatch && hasCurrentLocation ? (
        <Stack spacing="half">
          <div className="flex items-center gap-2" data-testid="visits:drawer-subpage-location-now">
            {hasAssignedLocation && <Tag theme="gray">Now</Tag>}
            <Body asChild={true} tertiary={true} className="hyphens-auto break-words uppercase">
              <div>
                {currentRoom} {currentBed}
              </div>
            </Body>
          </div>
          <div className="flex items-center gap-2" data-testid="visits:drawer-subpage-location-assigned">
            {hasAssignedLocation && (
              <Tag theme="dark" size="piwakawaka">
                Assigned
              </Tag>
            )}
            <Body asChild={true} tertiary={true} className="hyphens-auto break-words uppercase">
              <div>
                {assignedRoom} {assignedBed}
              </div>
            </Body>
          </div>
        </Stack>
      ) : (
        <Body
          asChild={true}
          tertiary={true}
          className="hyphens-auto break-words uppercase"
          data-testid="visits:drawer-subpage-location"
        >
          <div>
            {assignedRoom} {assignedBed}
          </div>
        </Body>
      )}
    </div>
  )
}

export default VisitSubpageLocationTag
