import {
  type ComponentPropsWithoutRef,
  type FC,
  type ReactElement,
  type RefCallback,
  type SyntheticEvent,
  useMemo,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { v4 as uuid } from 'uuid'
import Checkbox from './checkbox/checkbox'
import SwitchItem from './switch-item/switch-item'

export interface CheckboxItemProps extends ComponentPropsWithoutRef<'input'> {
  checked?: boolean | undefined
  disabled?: boolean | undefined
  id: string
  indeterminate?: boolean | undefined
  label?: string
  loading?: boolean
  kind?: 'square' | 'circle' | 'switch'
  name?: string | undefined
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void
  required?: boolean
  value?: string
}

export const CheckboxItem: FC<CheckboxItemProps> = ({
  checked = false,
  children,
  disabled = false,
  id,
  indeterminate = false,
  label,
  loading = false,
  kind = 'square',
  name,
  onChange,
  required,
  value,
  className,
  ...attributes
}): ReactElement => {
  const { 'aria-label': ariaLabel, ...rest } = attributes

  const generatedID = useMemo(() => {
    return `${id}_${uuid()}`
  }, [id])

  const InputRef: RefCallback<HTMLInputElement> = (inputRef) => {
    const input = inputRef

    if (input) {
      input.indeterminate = indeterminate
    }
  }

  return (
    <div
      className={twMerge(
        'relative rounded border-2 border-transparent py-[0.125rem]',
        'hover:bg-dust',
        disabled && 'text-gray-400 hover:bg-transparent',
        'has-[:focus-visible]:border-kereru',
        className,
      )}
    >
      <input
        className="absolute left-0 top-0 h-0 w-0 opacity-0"
        checked={checked}
        disabled={disabled}
        required={required}
        id={generatedID}
        name={name}
        value={value}
        type="checkbox"
        data-testid="components:checkbox-item:input"
        ref={InputRef}
        onChange={onChange}
        {...rest}
      />

      {(kind === 'square' || kind === 'circle') && (
        <Checkbox
          aria-label={ariaLabel}
          checked={checked}
          disabled={disabled}
          id={generatedID}
          indeterminate={indeterminate}
          kind={kind}
          label={label}
          loading={loading}
        >
          {children}
        </Checkbox>
      )}

      {kind === 'switch' && (
        <SwitchItem
          aria-label={ariaLabel}
          checked={checked}
          disabled={disabled}
          id={generatedID}
          label={label}
          loading={loading}
        >
          {children}
        </SwitchItem>
      )}
    </div>
  )
}
