import {
  type ChangeEvent,
  type ComponentPropsWithRef,
  type FC,
  type ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { v4 as uuid } from 'uuid'
import { RemainingCharacters } from '../remaining-characters/remaining-characters'

export interface TextAreaProps extends ComponentPropsWithRef<'textarea'> {
  disabled?: boolean
  hardLimit?: boolean
  id?: string
  maxLength?: number
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
}

export const TextArea: FC<TextAreaProps> = ({
  disabled,
  hardLimit = false,
  id,
  maxLength,
  onChange,
  value,
  ...attributes
}): ReactElement => {
  const controlID = useMemo(() => {
    return id || uuid()
  }, [id])

  const counterID = useMemo(() => {
    return `character-counter_${uuid()}`
  }, [])

  const [currentValue, setCurrentValue] = useState<string>(value || '')

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    } else {
      setCurrentValue('')
    }
  }, [value])

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setCurrentValue(event.currentTarget.value)

    if (onChange) {
      onChange(event)
    }
  }

  return (
    <>
      <div
        className={twMerge(
          'relative w-full rounded-md border-2 focus-within:border-kereru',
          disabled ? 'bg-gray-100 text-gray-400' : 'bg-white',
        )}
      >
        <textarea
          className="absolute inset-0 resize-none border-0 bg-transparent p-3 text-inherit outline-0 placeholder:text-gray-400 disabled:cursor-not-allowed"
          disabled={disabled}
          aria-controls={maxLength ? counterID : undefined}
          id={controlID}
          onChange={handleChange}
          maxLength={hardLimit ? maxLength : undefined}
          value={currentValue}
          {...attributes}
        />

        <div className="invisible min-h-[6rem] whitespace-pre-wrap border-0 p-3" aria-hidden="true">
          {currentValue ? `${currentValue} ` : `${attributes.placeholder || ''} `}
        </div>
      </div>

      {maxLength && <RemainingCharacters id={counterID} maximum={maxLength} currentLength={currentValue?.length} />}
    </>
  )
}
