import {
  ApolloClient,
  type FetchResult,
  type GraphQLRequest,
  HttpLink,
  InMemoryCache,
  type Observable,
  from,
  fromPromise,
} from '@apollo/client'
import type { NetworkError } from '@apollo/client/errors'
import { setContext } from '@apollo/client/link/context'
import { type ErrorLink, type ErrorResponse, onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { datadogLogs } from '@datadog/browser-logs'
import type { GraphQLError, GraphQLFormattedError } from 'graphql'
import type { Result } from 'neverthrow'
import { ApiPaths, Paths } from './config/app'
import type { VisitByIdForFacilityId_visitByIdForFacilityId_demographics } from './graphql/type/VisitByIdForFacilityId'
import { type TokenQueryError, type TokenQueryResponse, refreshIdTokenAndSaveToStorage } from './service/auth'
import { getValueFromLocalStorage } from './service/storage'
import { HttpStatusCodes } from './utility/http'
import { windowNavigateTo } from './utility/window'

export interface AppSyncError extends GraphQLError {
  errorType: string
}

/**
 * Safely return boolean if at least one errorType on an array of GraphQLError-like objects match.
 */
export function hasGraphQLErrorCode(
  graphQLErrors: readonly GraphQLFormattedError[] | undefined,
  type: 'Unauthorized',
): boolean {
  return (
    graphQLErrors !== undefined &&
    Array.isArray(graphQLErrors) &&
    (graphQLErrors as AppSyncError[]).some(({ errorType }) => errorType === type)
  )
}

/**
 * Safely return boolean if statusCode on an Error matches.
 */
export function isNetworkErrorCode(error: NetworkError | { statusCode?: number } | undefined, code: number): boolean {
  return error !== undefined && error !== null && 'statusCode' in error && error?.statusCode === code
}

/**
 * No Auth if Operation is "LoginClinician", otherwise set 'authorization' header using ID Token.
 *
 * @param operationName GraphQL OperationName
 */
export function getAuthRelatedHeaderForOperation(operationName: string | undefined) {
  if (operationName === 'LoginClinician') {
    return {}
  }

  const idToken = getValueFromLocalStorage<string>('id-token')

  if (!idToken) {
    datadogLogs.logger.warn(`Tried to perform operation ${operationName} without id token`)
  }

  return { authorization: idToken }
}

type AuthLinkContextSetter = (
  operation: Pick<GraphQLRequest, 'operationName'>,
  prevContext: undefined | { headers?: Record<string, unknown> },
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
) => Promise<any> | any

/**
 * Set different authentication headers based on which GraphQL request is dispactched.
 */
export const authLinkSetContext: AuthLinkContextSetter = ({ operationName }, prevContext) => {
  return {
    headers: {
      ...prevContext?.headers,
      ...getAuthRelatedHeaderForOperation(operationName),
    },
  }
}

type ErrorFallbackHandler = (
  error: Pick<ErrorResponse, 'graphQLErrors' | 'networkError'>,
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
) => Observable<FetchResult> | void

/**
 * Handle GraphQL network errors (400, 401, 403, 500). Retry using Refresh Token if 401/403,
 * logging for 400 & 500 errors, using different levels.
 */
export const errorFallbackLinkOnError: ErrorFallbackHandler = ({ graphQLErrors, networkError }) => {
  const isBadRequest = isNetworkErrorCode(networkError, HttpStatusCodes.BadRequest)
  const isInternalServerError = isNetworkErrorCode(networkError, HttpStatusCodes.InternalServerError)
  const userIsForbidden = isNetworkErrorCode(networkError, HttpStatusCodes.Forbidden)

  const userIsGraphQLUnauthorized = hasGraphQLErrorCode(graphQLErrors, 'Unauthorized')
  const userIsNetworkUnauthorized = isNetworkErrorCode(networkError, HttpStatusCodes.Unauthorized)

  // Redirect user to the Logout route if user is unauthenticated (Network-level or GraphQL-level), or forbidden
  if (userIsGraphQLUnauthorized || userIsNetworkUnauthorized || userIsForbidden) {
    // We don't want the user being able to go back to the previous page, so we'll use replace, instead of assign.
    windowNavigateTo(Paths.Logout)
  }

  // Capture GraphQL bad request
  if (isBadRequest) {
    datadogLogs.logger.warn('Bad Request', {
      networkError,
    })
  }

  // Capture internal server error
  if (isInternalServerError) {
    datadogLogs.logger.error('Internal server error', {
      networkError,
    })
  }
}

let refreshViaLink: Promise<Result<TokenQueryResponse, TokenQueryError>> | undefined

/**
 * If we receive an "Unauthorized" network error, we attempt to use the users Refresh Token
 * to get a new ID Token. We then hand off to the next Link/Operation to continue.
 */
export const refreshTokenLinkOnError: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
  operation,
  forward,
  response,
}) => {
  const userIsGraphQLUnauthorized = hasGraphQLErrorCode(graphQLErrors, 'Unauthorized')
  const userIsNetworkUnauthorized = isNetworkErrorCode(networkError, HttpStatusCodes.Unauthorized)

  if (userIsGraphQLUnauthorized || userIsNetworkUnauthorized) {
    const refreshToken = getValueFromLocalStorage<string>('refresh-token')

    // Guard to ensure a Refresh Token exists
    if (refreshToken === undefined || refreshToken === null) {
      return
    }

    if (!refreshViaLink) {
      // Otherwise, attempt to get a new ID Token using the Refresh Token
      refreshViaLink = refreshIdTokenAndSaveToStorage(refreshToken)
        .then((result) => {
          // Clear any follow on errors
          if (result.isOk() && response) {
            response.errors = undefined
          }

          return result
          /**
           * Just leaving a comment here about 'handling' errors here.
           * Right now, anything critical is logged to Datadog. It would
           * be nice to display a message to the user if their Refresh Token
           * has expired too. Future thing though.
           */
        })
        .finally(() => {
          refreshViaLink = undefined
        })
    }

    // Return 'Refresh Token' Observable and next operations.
    // This ensures that Apollo continues with queued up requests.
    // IMPORTANT: Don't change this line unless you have a good reason. It's not covered by test coverage.
    return fromPromise(refreshViaLink).flatMap(() => forward(operation))
  }
}

// Create links
const authLink = setContext(authLinkSetContext)
const errorFallbackLink = onError(errorFallbackLinkOnError)
const httpLink = new HttpLink({ uri: ApiPaths.Graphql })
const refreshTokenLink = onError(refreshTokenLinkOnError)
const retryLink = new RetryLink({
  attempts: {
    max: 2,
  },
})

export const configureClient = () => {
  return new ApolloClient({
    assumeImmutableResults: true,

    cache: new InMemoryCache({
      typePolicies: {
        ClinicianUserFacilityConfiguration: {
          keyFields: ['email', 'facilityId'],
        },
        ClinicianUserFacilityConfigurationsForFacilityId: {
          keyFields: ['email', 'facilityId'],
        },
        Visit: {
          fields: {
            demographics: {
              merge(
                existing: VisitByIdForFacilityId_visitByIdForFacilityId_demographics,
                incoming: VisitByIdForFacilityId_visitByIdForFacilityId_demographics,
              ): VisitByIdForFacilityId_visitByIdForFacilityId_demographics {
                return { ...existing, ...incoming }
              },
            },
          },
        },
      },
    }),

    // IMPORTANT: The order of these links matters. errorFallbackLink should be the "first" in the chain (it needs to run last)
    link: from([errorFallbackLink, retryLink, refreshTokenLink, authLink, httpLink]),
  })
}
