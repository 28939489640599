import {
  mdiCheckCircle,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiMinusBox,
  mdiMinusCircle,
} from '@mdi/js'
import type { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Icon } from '../../icon/icon'
import { Status } from '../../status/status'
import { Body } from '../../typography/body'

export interface CheckboxProps {
  checked?: boolean
  children?: ReactNode
  disabled?: boolean
  id?: string
  indeterminate?: boolean
  kind?: 'square' | 'circle'
  label?: string | undefined
  loading?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  children,
  disabled = false,
  id,
  indeterminate,
  kind = 'square',
  label,
  loading,
  ...attributes
}) => {
  let path = ''

  if (kind === 'circle') {
    path = mdiCheckboxBlankCircleOutline

    if (checked) {
      path = mdiCheckCircle
    }

    if (indeterminate) {
      path = mdiMinusCircle
    }
  }

  if (kind === 'square') {
    path = mdiCheckboxBlankOutline

    if (checked) {
      path = mdiCheckboxMarked
    }

    if (indeterminate) {
      path = mdiMinusBox
    }
  }

  return (
    <label
      className={twMerge('flex cursor-pointer items-center gap-2', disabled && 'cursor-not-allowed')}
      htmlFor={id}
      data-testid="components:checkbox-item:label"
      {...attributes}
    >
      {loading ? (
        <Status className="shrink-0 grow-0" status="loading" size="kea" />
      ) : (
        <Icon
          className={twMerge(
            'shrink-0 grow-0',
            checked || indeterminate ? '!text-kereru' : '!text-stone',
            disabled && '!text-gray-100',
          )}
          path={path}
          size={4}
        />
      )}

      {label && <Body>{label}</Body>}
      {children && <Body>{children}</Body>}
    </label>
  )
}

export default Checkbox
