import type { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Status } from '../../status/status'
import { Body } from '../../typography/body'

export interface SwitchProps {
  checked?: boolean
  children?: ReactNode
  disabled?: boolean
  id?: string
  label?: string | null | undefined
  loading?: boolean
}

const SwitchItem: FC<SwitchProps> = ({ checked, children, disabled = false, id, label, loading, ...attributes }) => {
  return (
    <label
      className={twMerge('flex cursor-pointer items-center gap-2', disabled && 'cursor-not-allowed')}
      htmlFor={id}
      data-testid="components:checkbox-item:label"
      {...attributes}
    >
      {loading ? (
        <Status className="shrink-0 grow-0" status="loading" size="kea" />
      ) : (
        <div
          className={twMerge(
            'box-content h-5 w-10 items-center rounded-full bg-stone p-1',
            checked && 'bg-kereru',
            disabled && 'bg-gray-100',
          )}
        >
          <div
            className={twMerge('h-5 w-5 rounded-full bg-white shadow transition-transform', checked && 'translate-x-5')}
          />
        </div>
      )}

      {label && <Body>{label}</Body>}
      {children && <Body>{children}</Body>}
    </label>
  )
}

export default SwitchItem
