import { Icon } from '@katoa/anatomy'
import { mdiCheckboxMarkedOutline, mdiClockTimeThreeOutline } from '@mdi/js'
import type { FC, MouseEventHandler } from 'react'
import { ListViewHeader, ListViewHeaderSortable } from '../../../ui-components/list-view/list-view'
import type { SortableColumnKey } from './sorting'

interface NotificationsViewHeaderProps {
  onSort: MouseEventHandler
  sortDirection: 'ascending' | 'descending'
  sortByColumn: SortableColumnKey
}
const NotificationsViewHeader: FC<NotificationsViewHeaderProps> = ({ onSort, sortDirection, sortByColumn }) => {
  return (
    <ListViewHeader className="grid-cols-[2rem_3rem_1fr_4rem] xl:grid-cols-[2rem_5rem_1fr_4rem]">
      <ListViewHeaderSortable
        aria-label="Sort by Completed"
        sortDirection={sortByColumn === 'completion' ? sortDirection : undefined}
        onClick={onSort}
        data-column-name="completion"
      >
        <Icon size={2} path={mdiCheckboxMarkedOutline} />
      </ListViewHeaderSortable>
      <ListViewHeaderSortable
        aria-label="Sort by Room"
        sortDirection={sortByColumn === 'location' ? sortDirection : undefined}
        onClick={onSort}
        data-column-name="location"
      >
        RM
      </ListViewHeaderSortable>
      <ListViewHeaderSortable
        aria-label="Sort by Patient"
        sortDirection={sortByColumn === 'patient' ? sortDirection : undefined}
        onClick={onSort}
        data-column-name="patient"
      >
        Patient
      </ListViewHeaderSortable>
      <ListViewHeaderSortable
        aria-label="Sort by Requested at"
        sortDirection={sortByColumn === 'timestamp' ? sortDirection : undefined}
        onClick={onSort}
        data-column-name="timestamp"
      >
        <Icon size={2} path={mdiClockTimeThreeOutline} />
      </ListViewHeaderSortable>
    </ListViewHeader>
  )
}

export default NotificationsViewHeader
