import {
  Children,
  type ComponentPropsWithoutRef,
  type FC,
  type ReactElement,
  type ReactNode,
  cloneElement,
  isValidElement,
  useMemo,
} from 'react'
import { v4 as uuid } from 'uuid'
import { Stack } from '../stack/stack'
import { Status } from '../status/status'
import { Caption } from '../typography/caption'

export interface FormFieldProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode
  id?: string
  label?: string
  message?: ReactNode
  optional?: boolean
  status?: 'loading' | 'success' | 'error' | null
  supportingText?: string
}

export const FormField: FC<FormFieldProps> = ({
  children,
  id,
  label,
  message,
  optional,
  status,
  supportingText,
  ...attributes
}): ReactElement => {
  const htmlProps = { ...attributes }
  const generatedID = useMemo(() => {
    return id || uuid()
  }, [id])

  const messages = {
    loading: 'Saving',
    success: 'Saved',
    error: 'Error',
    none: '',
  }

  let statusMessage: ReactNode = ''

  if (status) {
    statusMessage = messages[status]
  }

  if (message) {
    statusMessage = message
  }

  return (
    <div className="mx-0 flex basis-full flex-col justify-end border-0 p-0" {...htmlProps}>
      <Stack spacing="quarter">
        {label && (
          <label className="flex w-fit items-baseline gap-1 p-0" id={`${generatedID}_label`} htmlFor={generatedID}>
            <strong>{label}</strong>
            {optional && <Caption tertiary={true}>(Optional)</Caption>}
          </label>
        )}

        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return (
              <>
                {cloneElement(child as ReactElement, {
                  id: generatedID,
                })}
              </>
            )
          }

          return null
        })}

        {supportingText && (
          <Caption asChild={true} tertiary={true}>
            <p>{supportingText}</p>
          </Caption>
        )}

        {status !== undefined && (
          <Status message={statusMessage} messagePosition="after" status={status} size="piwakawaka" />
        )}
      </Stack>
    </div>
  )
}
