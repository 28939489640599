import type { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import getRedirectFromSearch from '../../../utility/get-redirect-from-search'

const RedirectFromSearch: FC<{ children: ReactNode }> = ({ children }) => {
  const { search } = useLocation()

  if (search) {
    const redirectTo = getRedirectFromSearch(search)

    if (redirectTo) {
      return <Navigate to={redirectTo} />
    }
  }

  return <>{children}</>
}

export default RedirectFromSearch
