import { gql } from '@apollo/client'
import { FACILITY_ENABLED_FEATURES_FRAGMENT } from '../fragments/facility-enabled-features'

// The Facility Base contains the bare minimum fields that are required to render the shell of the page.
// Currently, this is the name, logo, enabled features, and deploy flags of the Facility.
// Any fields required to render the content of the page should be in a separate query in the page component.

const GET_FACILITY_BASE_QUERY = gql`
  ${FACILITY_ENABLED_FEATURES_FRAGMENT}
  query FacilityBase($input: FacilityByIdInput!) {
    facilityById(input: $input) {
      id
      name
      assetUrls {
        logo
      }
      ...FacilityEnabledFeaturesFragment
    }
  }
`

export default GET_FACILITY_BASE_QUERY
