import { useMutation, useQuery } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import {
  Alert,
  Body,
  Button,
  Caption,
  Dialog,
  DialogContent,
  DialogFooter,
  FormField,
  Stack,
  Status,
  TextArea,
} from '@katoa/anatomy'
import { mdiCheckCircleOutline, mdiMessageOutline, mdiSend } from '@mdi/js'
import { type ChangeEvent, type FC, type MouseEventHandler, useState } from 'react'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import SEND_SMS_MESSAGE_MUTATION from '../../../graphql/mutations/send-sms-message'
import VISIT_BY_ID_FOR_FACILIITY_ID_QUERY from '../../../graphql/queries/get-visit-by-id-for-facility-id'
import type { SendSMSMessage, SendSMSMessageVariables } from '../../../graphql/type/SendSMSMessage'
import type { VisitByIdForFacilityId } from '../../../graphql/type/VisitByIdForFacilityId'
import { formatName } from '../../../utility/name'
import { formatPhoneNumber } from '../../../utility/phone'
import type { QuickMessage } from '../use-quick-messages/use-quick-messages'

// To hide caller id in USA you can enter a code as prefix
// The code is specific to the country - e.g NZ uses 0197
const HIDE_CALLER_ID_CODE = '*67'
const MESSAGE_MAX_LENGTH = 300
const SEND_ERROR_MESSAGE = 'Something has gone wrong while your message was being sent. Please try again later.'

export interface SendMessageDialogProps {
  backURL: string
  quickMessages: QuickMessage[]
}

const SendMessageDialog: FC<SendMessageDialogProps> = ({ backURL, quickMessages }) => {
  const { facilityId, visitId } = useParams()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isSuccessfullySent, setIsSuccessfullySent] = useState<boolean>(false)

  const {
    data,
    loading: visitsLoading,
    error: visitsError,
  } = useQuery<VisitByIdForFacilityId>(VISIT_BY_ID_FOR_FACILIITY_ID_QUERY, {
    variables: {
      input: {
        visitId,
        facilityId,
      },
    },
  })
  const visit = data?.visitByIdForFacilityId

  const [sendSMSMessage, { loading }] = useMutation<SendSMSMessage, SendSMSMessageVariables>(
    SEND_SMS_MESSAGE_MUTATION,
    {
      onCompleted: () => {
        setIsSuccessfullySent(true)
        datadogRum.addAction('send Direct Message')
      },
      onError: () => {
        setErrorMessage(SEND_ERROR_MESSAGE)
      },
    },
  )

  if ((!visitsLoading && !visit) || visitsError) {
    return <Navigate to={backURL} />
  }

  const onSendClicked = (): void => {
    setErrorMessage(null)
    setIsSuccessfullySent(false)
    if (visitId) {
      sendSMSMessage({ variables: { input: { facilityId, message, visitId } } })
    }
  }

  const handleQuickMessageClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event?.currentTarget?.dataset?.messageText) {
      setMessage(`${message}${event.currentTarget.dataset.messageText}`)
    }
  }

  const patientPhoneNumber =
    visit?.primaryPhoneNumber || visit?.demographics?.mobileNumber || visit?.demographics?.homeNumber

  const phoneNumber = formatPhoneNumber(patientPhoneNumber) || undefined

  const subtitle = phoneNumber
    ? {
        label: phoneNumber,
        href: `tel:${HIDE_CALLER_ID_CODE}${patientPhoneNumber}`,
        onClick: (): void => {},
      }
    : undefined

  return (
    <Dialog
      title={formatName(visit?.demographics ?? null)}
      subtitle={subtitle}
      loading={visitsLoading}
      isVisible={true}
      size="piwakawaka"
      data-testid="dialog:send-message"
      data-dd-action-name="send message dialog"
      data-dd-privacy="mask"
      onRequestDismiss={(): void => {
        navigate({
          pathname: backURL,
          search: searchParams.toString(),
        })
      }}
    >
      <DialogContent>
        {visitsLoading && <Status status="loading" />}
        {!visitsLoading && (
          <Stack spacing={1}>
            <Body>
              Send a one-way message to this patient. They will not be able to respond and they will not be able to see
              your phone number.
            </Body>
            <FormField label={isSuccessfullySent ? 'Your message' : 'Write a message'}>
              <TextArea
                disabled={loading || isSuccessfullySent}
                autoComplete="on"
                data-testid="patients:message"
                name="message"
                required={true}
                onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>): void => {
                  setMessage(value)
                }}
                maxLength={MESSAGE_MAX_LENGTH}
                placeholder="Enter your message here"
                value={message}
              />
            </FormField>
            {errorMessage && (
              <Alert intent="error">
                <Caption asChild={true}>
                  <p>{errorMessage}</p>
                </Caption>
              </Alert>
            )}

            {quickMessages && (
              <div className="flex flex-wrap gap-2">
                {quickMessages.map(({ label, text }) => {
                  return (
                    <Button
                      iconBefore={mdiMessageOutline}
                      size="piwakawaka"
                      key={label}
                      intent="neutral"
                      soft={true}
                      element="button"
                      disabled={loading || isSuccessfullySent}
                      data-message-text={text}
                      onClick={handleQuickMessageClick}
                    >
                      {label}
                    </Button>
                  )
                })}
              </div>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogFooter>
        <Stack spacing={1}>
          <Button
            success={isSuccessfullySent}
            iconAfter={isSuccessfullySent ? mdiCheckCircleOutline : mdiSend}
            disabled={isSuccessfullySent || loading || !message || message.length > MESSAGE_MAX_LENGTH}
            element="button"
            block={true}
            loading={loading}
            onClick={(): void => onSendClicked()}
            data-testid="button:send-dm"
          >
            {isSuccessfullySent ? 'Message Sent' : 'Send'}
          </Button>
          <Button
            element="link"
            to={{
              pathname: '../invite/sms/',
              search: searchParams.toString(),
            }}
            intent="neutral"
            collapsed={true}
            block={true}
          >
            Wrong mobile number or resending an invite?
          </Button>
        </Stack>
      </DialogFooter>
    </Dialog>
  )
}

export default SendMessageDialog
