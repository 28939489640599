import type { FC, ReactElement } from 'react'
import { Divider } from '../divider/divider'
import { ImageSkeletonLoader } from '../skeleton-loader/image-skeleton-loader'
import { ParagraphSkeletonLoader } from '../skeleton-loader/paragraph-skeleton-loader'
import { TitleSkeletonLoader } from '../skeleton-loader/title-skeleton-loader'
import { Stack } from '../stack/stack'
import { CardGutter } from './card-gutter'

export const CardLoader: FC = (): ReactElement => (
  <div className="pb-48">
    <ImageSkeletonLoader />
    <CardGutter>
      <Stack spacing={2}>
        <TitleSkeletonLoader />
        <Divider />
        <ParagraphSkeletonLoader />
        <ParagraphSkeletonLoader />
        <ParagraphSkeletonLoader />
        <ParagraphSkeletonLoader />
      </Stack>
    </CardGutter>
  </div>
)
