import {
  Button,
  Caption,
  Card,
  CheckboxItem,
  Constrain,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Stack,
  useToast,
} from '@katoa/anatomy'
import {
  mdiBell,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiClose,
  mdiCloseCircleOutline,
  mdiContentCopy,
  mdiFileDocumentOutline,
  mdiMenuDown,
  mdiMenuUp,
  mdiMessageProcessing,
  mdiPhone,
  mdiTextBoxCheck,
  mdiTextBoxRemove,
} from '@mdi/js'
import Icon from '@mdi/react'
import { differenceInYears } from 'date-fns'
import { type FC, useEffect, useRef, useState } from 'react'
import { Link, Outlet, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import NotificationsViewHeader from '../../app/notifications/notifications-list/notifications-list-view-header/notifications-list-view-header'
import SendMessageDialog from '../../app/patient-communication/send-message-dialog/send-message-dialog'
import ListView, { ListViewItem } from '../../app/ui-components/list-view/list-view'
import MaybeLink from '../../app/ui-components/maybe-link/maybe-link'
import VisitSubpageLocationTag from '../../app/visits/visit-subpage/visit-subpage-location-tag'
import VisitsListConnectionError from '../../app/visits/visits-list/visits-list-content/visits-list-connection-error'
import VisitsListLoading from '../../app/visits/visits-list/visits-list-content/visits-list-loading'
import { useSortColumn } from '../../app/visits/visits-list/visits-list-view-header/use-sort-column'
import { displaySexAcronym } from '../../core/sex'
import { Sex } from '../../graphql/global-types'
import { getValueFromLocalStorage } from '../../service/storage'
import { formatName } from '../../utility/name'
import { formatPhoneNumber } from '../../utility/phone'

interface IncidentalFinding {
  id: string
  visitId: string
  createdAt: string
  endedAt: string | null
  lengthOfStay: number | null // in minutes
  assignedBed: string | null
  assignedRoom: string | null
  currentBed: string | null
  currentRoom: string | null
  demographics: {
    dob: string
    firstName: string
    lastName: string
    sex: Sex
  } | null
  canReceiveSms: boolean
  phoneNumber: string | null
  outcome: 'admit' | 'transfer' | 'discharge' | 'outcome' | 'observation' | 'lwbs' | 'death' | null
  valid: boolean | null
  completed: boolean
  history: {
    id: string
    type: string
    title: string
    details?: string[] | undefined
    performedAt: string
    performedBy: string
  }[]
  diagnosticTestName: string
  findings: string[]
  diagnosticTestResult: string
}

const data: IncidentalFinding[] = [
  {
    id: '3c4d5e6f-1a2b-3c4d-5e6f-1a2b3c4d5e6f',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-11T21:57:03.950Z',
    endedAt: null,
    lengthOfStay: 2 * 24 * 60,
    assignedBed: '23',
    assignedRoom: 'W301',
    currentBed: '56',
    currentRoom: 'W301',
    demographics: {
      dob: '1972-03-15',
      firstName: 'Martin',
      lastName: 'Jones',
      sex: Sex.Female,
    },
    canReceiveSms: true,
    phoneNumber: '+15559876543',
    outcome: 'admit',
    valid: true,
    completed: true,
    history: [
      {
        id: '234234-1a2b-3c4d-5e6f-178b3c4d5e6f',
        type: 'complete',
        title: 'Marked as complete',
        performedAt: '2024-11-13T14:25:00.000Z',
        performedBy: 'Jon Ward MD',
      },
      {
        id: '7b9e2f4d-6a1c-4835-9d2b-8c3e5f7a9b4d',
        type: 'invalid',
        details: ['No clinically relevant findings', 'Too much information'], // make generic
        title: 'Marked as valid',
        performedAt: '2024-11-13T14:24:00.000Z', // performed
        performedBy: 'Vital AI',
      },
    ],
    diagnosticTestName: 'Radiograph Chest',
    findings: ['Peripheral ground-glass opacity in right lower lobe measuring 2.3cm with spiculated margins'], // as a list
    diagnosticTestResult:
      'FINDINGS:\nChest radiograph demonstrates a 2.3 cm peripheral ground-glass opacity in the right lower lobe with spiculated margins. The lesion shows irregular borders and radiating linear strands extending into the surrounding lung parenchyma, concerning for a primary pulmonary malignancy.\n\nThe remainder of the lung fields are clear without evidence of consolidation, effusion, or pneumothorax. Heart size and mediastinal contours appear within normal limits. No hilar lymphadenopathy identified.\n\nIMPRESSION:\n1. 2.3 cm spiculated ground-glass opacity in right lower lobe highly suspicious for primary lung cancer\n2. Recommend expedited CT chest with contrast for further characterization and staging\n3. Consider PET/CT depending on CT findings\n4. Pulmonary consultation advised',
  },

  {
    id: 'fghfgh-3c4d-5e6f-1a2b-3c4d5e6f1a2b',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-15T08:10:00.000Z',
    endedAt: null,
    lengthOfStay: 3 * 60, // in mins
    assignedBed: '89',
    assignedRoom: 'W205',
    currentBed: '89',
    currentRoom: 'W205',
    demographics: {
      dob: '1955-12-25',
      firstName: 'Emily',
      lastName: 'Davis',
      sex: Sex.Female,
    },
    canReceiveSms: true,
    phoneNumber: '+15557778888',
    outcome: null,
    valid: false,
    completed: true,
    history: [
      {
        id: 'dfgdfg-3c4d-5e6f-1a2b-3c4d5e6f1a2b',
        type: 'complete',
        title: 'Marked as complete',
        performedAt: '2024-11-15T09:30:00.000Z',
        performedBy: 'Justin Shrager MD',
      },
      {
        id: '4d5e6f1a-2b3c-4d5e-6f1a-2b3c4d5e6f1a',
        type: 'invalid',
        title: 'Marked as invalid',
        details: ['Previously known finding'],
        performedAt: '2024-11-15T09:00:00.000Z',
        performedBy: 'Jon Ward MD',
      },
    ],
    diagnosticTestName: 'Ultrasound Thyroid - 1 view',
    findings: ['Calcified 1.2cm nodule in left thyroid lobe with internal vascularity on Doppler'], // as a list
    diagnosticTestResult:
      'Thyroid ultrasound demonstrates a 1.2 cm well-defined nodule in the left thyroid lobe. The nodule shows internal calcification and increased vascularity on Doppler imaging. No suspicious cervical lymphadenopathy identified.\n\nThe remainder of the thyroid gland appears normal in size and echogenicity. No additional nodules or masses seen.\n\nIMPRESSION:\n1. 1.2 cm calcified nodule in left thyroid lobe with internal vascularity\n2. Given the size and vascularity, fine needle aspiration biopsy is recommended for further evaluation\n3. Consider endocrinology referral for management\n4. Recommend follow-up ultrasound in 6-12 months if biopsy is benign',
  },
  {
    id: '1068b2e4-5395-4a12-8333-7943dbb3fd3f',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-11T21:56:13.249Z',
    endedAt: null,
    lengthOfStay: 5 * 60,
    assignedBed: '23',
    assignedRoom: 'W301',
    currentBed: '23',
    currentRoom: 'W301',
    demographics: {
      dob: '2021-11-12',
      firstName: 'Zelda',
      lastName: 'Feeney',
      sex: Sex.Female,
    },
    canReceiveSms: false,
    phoneNumber: null,
    outcome: 'outcome',
    valid: false,
    completed: true,
    history: [
      {
        id: '2344-2344-2344-2344-2344',
        type: 'complete',
        title: 'Marked as complete',
        performedAt: '2024-11-11T21:57:03.950Z',
        performedBy: 'Jon Ward MD',
      },
      {
        id: 'f234r-434r-2344-2344-2344',
        type: 'invalid',
        title: 'Marked as invalid',
        details: ['No clinically relevant findings', 'Too much information'],
        performedAt: '2024-11-11T21:57:03.950Z',
        performedBy: 'Justin Shrager MD',
      },
    ],
    diagnosticTestName: 'Radiograph Chest',
    findings: ['1.8cm pulmonary nodule'], // as a list
    diagnosticTestResult:
      'Chest radiograph demonstrates a 1.8 cm pulmonary nodule in the right upper lobe. The nodule appears suspicious with irregular borders and increased density. There is associated pleural thickening and subtle retraction of adjacent lung tissue.\n\nThe remainder of the lung fields show patchy areas of increased interstitial markings. Heart size is mildly enlarged. Mediastinal contours are widened, suggesting possible lymphadenopathy. No pleural effusions identified.\n\nIMPRESSION:\n1. 1.8 cm suspicious pulmonary nodule in right upper lobe with concerning features\n2. Recommend urgent CT chest with contrast for further characterization\n3. Consider PET scan depending on CT findings\n4. Pulmonary consultation advised for management planning',
  },
  {
    id: '2f1a852c-6b3d-4e9a-9d4f-a1b2c3d4e5f6',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-12T09:30:00.000Z',
    endedAt: '2024-11-12T11:45:00.000Z',
    lengthOfStay: 5 * 24 * 60 + 25,
    assignedBed: '89',
    assignedRoom: 'W205',
    currentBed: 'B1',
    currentRoom: 'ER1',
    demographics: {
      dob: '1985-06-20',
      firstName: 'John',
      lastName: 'Doe',
      sex: Sex.Male,
    },
    canReceiveSms: true,
    phoneNumber: '+15551234567',
    outcome: 'discharge',
    valid: null,
    completed: false,
    history: [],
    diagnosticTestName: 'Xray Chest - 4 views',
    findings: ['Suspicious pulmonary nodule found on chest x-ray'], // as a list
    diagnosticTestResult:
      'FINDINGS:\nChest x-ray demonstrates a suspicious pulmonary nodule in the right lung field. The nodule appears to have irregular borders and increased density compared to surrounding lung tissue. No definite calcifications are identified within the nodule.\n\nThe remainder of the lung fields are clear without focal consolidation, effusion or pneumothorax. Heart size and mediastinal contours appear within normal limits. Osseous structures are intact.\n\nIMPRESSION:\n1. Suspicious pulmonary nodule requiring further characterization\n2. Recommend CT chest with contrast for detailed evaluation\n3. Clinical correlation and follow-up advised',
  },
  {
    id: '3c5e6f-1a2b-3c4d-5e6f-1a2b3c4d5e6f',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-13T14:20:00.000Z',
    endedAt: null,
    lengthOfStay: 2 * 60 + 46,
    assignedBed: '23',
    assignedRoom: 'W301',
    currentBed: '42',
    currentRoom: 'W301',
    demographics: {
      dob: '1972-03-15',
      firstName: 'Jane',
      lastName: 'Smith',
      sex: Sex.Other,
    },
    canReceiveSms: true,
    phoneNumber: '+15559876543',
    outcome: 'admit',
    valid: true,
    completed: true,
    history: [
      {
        id: '09808-1a2b-3c4d-5e6f-1a2b3c4d5e6f',
        type: 'complete',
        title: 'Marked as complete',
        performedAt: '2024-11-13T14:25:00.000Z',
        performedBy: 'Jon Ward MD',
      },
      {
        id: '7b9e2f4d-6a1c-4835-9d2b-8c3e5f7a9b4d',
        type: 'valid',
        title: 'Marked as valid',
        performedAt: '2024-11-13T14:25:00.000Z',
        performedBy: 'Justin Shrager MD',
      },
    ],
    diagnosticTestName: 'CT Upper Chest',
    findings: [
      'Irregular 4.2cm mass in the right upper lobe with spiculated margins and associated mediastinal lymphadenopathy concerning for primary lung malignancy',
    ], // as a list
    diagnosticTestResult:
      'Chest CT demonstrates an irregular 4.2 cm mass in the right upper lobe with spiculated margins and central necrosis. Multiple enlarged mediastinal lymph nodes are present, with the largest measuring 2.1 cm in short axis. No pleural effusion or chest wall invasion identified.\n\nThe remainder of the lung parenchyma shows scattered emphysematous changes. No additional suspicious pulmonary nodules or masses. Heart size is normal. No pericardial effusion.\n\nIMPRESSION:\n1. 4.2 cm spiculated right upper lobe mass with associated mediastinal lymphadenopathy highly concerning for primary lung malignancy\n2. Staging workup recommended including PET/CT and brain MRI\n3. Tissue diagnosis advised via CT-guided biopsy or bronchoscopy\n4. Pulmonary/Oncology consultation recommended for management planning',
  },
  {
    id: '4d6f1a-2b3c-4d5e-6f1a',
    visitId: '92c7a425-21cb-413b-9772-86e09411cee5',
    createdAt: '2024-11-14T18:45:00.000Z',
    endedAt: '2024-11-14T20:30:00.000Z',
    lengthOfStay: 8 * 60 + 32,
    assignedBed: '89',
    assignedRoom: 'W205',
    currentBed: 'B3',
    currentRoom: 'ER3',
    demographics: {
      dob: '1998-09-02',
      firstName: 'Michael',
      lastName: 'Johnson',
      sex: Sex.Male,
    },
    canReceiveSms: false,
    phoneNumber: null,
    outcome: 'discharge',
    valid: true,
    completed: false,
    history: [
      {
        id: '4d5f1a-2b3c-4d5e-6f1a-25e6f1a',
        type: 'valid',
        title: 'Marked as valid',
        performedAt: '2024-11-14T20:30:00.000Z',
        performedBy: 'Jon Ward MD',
      },
    ],
    diagnosticTestName: 'CT Abdomen',
    findings: ['3.5cm hypodense lesion in the right hepatic lobe with peripheral enhancement on contrast imaging'], // as a list
    diagnosticTestResult:
      'Abdominal CT with contrast demonstrates a 3.5 cm hypodense lesion in segment VII of the right hepatic lobe. The lesion shows peripheral enhancement in arterial phase with progressive centripetal fill-in on portal venous and delayed phases, characteristic of a hemangioma. No washout pattern observed.\n\nThe remainder of the liver parenchyma appears homogeneous without additional focal lesions. No intrahepatic or extrahepatic biliary ductal dilatation. Portal and hepatic veins are patent. No ascites or lymphadenopathy identified.\n\nIMPRESSION:\n1. 3.5 cm right hepatic lobe lesion with classic enhancement pattern for hemangioma\n2. No suspicious features to suggest malignancy\n3. Recommend follow-up ultrasound in 6-12 months to ensure stability\n4. Clinical correlation with liver function tests advised',
  },
]

const useMock = () => {
  const [key, setKey] = useState(Math.random())

  useEffect(() => {
    window.addEventListener('update', (event) => {
      if (event instanceof CustomEvent) {
        setKey(event.detail)
      }
    })
  }, [])

  return key
}

// Mock the APIs for testing
if (import.meta.env.MODE === 'test') {
  const { HttpResponse, http } = await import('msw')
  const { worker } = await import('../../mocks/setup')

  worker.use(
    http.get('/care-api/incidental-findings', async () => {
      return HttpResponse.json({ data }, { status: 200 })
    }),
    http.get('/care-api/incidental-findings/:id', async ({ params }) => {
      const item = data.find((item) => item.id === params.id)
      if (!item) {
        return HttpResponse.json({ error: 'Item not found' }, { status: 404 })
      }
      return HttpResponse.json(item, { status: 200 })
    }),
    http.post('/care-api/incidental-findings/:id/complete', async ({ request, params }) => {
      const { id } = params
      const { completed, currentUser } = (await request.json()) as {
        completed: boolean
        currentUser: string
      }
      // update mock data
      const item = data.find((item) => item.id === id)
      if (!item) {
        return HttpResponse.json({ error: 'Item not found' }, { status: 404 })
      }
      if (completed) {
        item.completed = completed
        item.history.unshift({
          id: Math.random().toString(36).substring(2, 15),
          type: 'complete',
          title: 'Marked as complete',
          performedAt: new Date().toISOString(),
          performedBy: currentUser,
        })
      } else {
        item.completed = false
        item.valid = null
        item.history.unshift({
          id: Math.random().toString(36).substring(2, 15),
          type: 'incomplete',
          title: 'Marked as incomplete',
          performedAt: new Date().toISOString(),
          performedBy: currentUser,
        })
      }
      window.dispatchEvent(new CustomEvent('update', { detail: Math.random() }))
      return HttpResponse.json({ success: true }, { status: 200 })
    }),
    http.post('/care-api/incidental-findings/:id/valid', async ({ params, request }) => {
      const { id } = params
      const { currentUser } = (await request.json()) as {
        currentUser: string
      }
      // update mock data
      const item = data.find((item) => item.id === id)
      if (!item) {
        return HttpResponse.json({ error: 'Item not found' }, { status: 404 })
      }

      item.valid = true
      item.history.unshift({
        id: Math.random().toString(36).substring(2, 15),
        type: 'valid',
        title: 'Marked as valid',
        performedAt: new Date().toISOString(),
        performedBy: currentUser,
      })
      window.dispatchEvent(new CustomEvent('update', { detail: Math.random() }))
      return HttpResponse.json({ success: true }, { status: 200 })
    }),

    http.post('/care-api/incidental-findings/:id/invalid', async ({ params, request }) => {
      const { id } = params
      const { currentUser, details } = (await request.json()) as {
        currentUser: string
        details: string[]
      }
      // update mock data
      const item = data.find((item) => item.id === id)
      if (!item) {
        return HttpResponse.json({ error: 'Item not found' }, { status: 404 })
      }
      item.valid = false
      item.completed = true
      item.history.unshift({
        id: Math.random().toString(36).substring(2, 15),
        type: 'invalid',
        title: 'Marked as invalid',
        performedAt: new Date().toISOString(),
        performedBy: currentUser,
        details,
      })
      window.dispatchEvent(new CustomEvent('update', { detail: Math.random() }))
      return HttpResponse.json({ success: true }, { status: 200 })
    }),
  )
}

const HIDE_CALLER_ID_CODE = '*67'

export const dialog =
  'text-inherit z-50 fixed w-full max-h-[calc(100dvh-32px)] overscroll-contain scrollbar-hide overflow-y-auto overflow-x-hidden hidden open:flex flex-col mx-auto max-w-lg top-auto sm:top-0 bottom-0 bg-white backdrop:bg-sky-800/50 rounded-t-3xl sm:rounded-3xl transition-discrete motion-reduce:transform-none translate-y-32 starting:open:translate-y-32 open:translate-y-0 opacity-0 starting:open:opacity-0 open:opacity-100 duration-200 backdrop:opacity-0 starting:open:backdrop:opacity-0 open:backdrop:opacity-100 backdrop:transition-all backdrop:duration-300 ease-in-out backdrop:ease-in-out motion-reduce:backdrop:transform-none'

const quickMessages = [{ label: 'Let you know....', text: 'Just to inform you of whats happening.' }]

const reasonsForInvalid = [
  {
    label: 'Previously known finding',
    value: 'previously_known_finding',
  },
  {
    label: 'Disclosure documented by provider',
    value: 'disclosure_documented_by_provider',
  },
  {
    label: 'Inaccurate Description',
    value: 'inaccurate_description',
  },
  {
    label: 'No clinically relevant findings',
    value: 'no_clinically_relevant_findings',
  },
  {
    label: 'No additional workup needed',
    value: 'no_additional_workup_needed',
  },
]

const PatientCommunication = () => {
  return <SendMessageDialog backURL=".." quickMessages={quickMessages} />
}

const ItemRow = ({
  id,
  visitId,
  findings,
  canReceiveMessages,
  fullName,
  age,
  sex,
  selected,
  lengthOfStay,
  currentRoom,
  valid,
  completed,
  currentUser,
  outcome,
  endedAt,
}: {
  id: string
  visitId: string
  findings: string[]
  canReceiveMessages: boolean
  fullName: string
  age?: number | null
  sex?: Sex | null
  selected: boolean
  lengthOfStay: number
  currentRoom: string | null
  outcome: 'admit' | 'transfer' | 'discharge' | 'outcome' | 'observation' | 'lwbs' | 'death' | null
  valid: boolean | null
  completed: boolean
  endedAt: string | null
  currentUser?: string | null
}) => {
  const openToast = useToast()
  const navigate = useNavigate()

  const formatDuration = (minutes: number): string => {
    if (minutes < 1) return '0m'

    const days = Math.floor(minutes / (24 * 60))
    const remainingHours = Math.floor((minutes % (24 * 60)) / 60)
    const remainingMinutes = minutes % 60

    const parts: string[] = []
    if (days > 0) {
      parts.push(`${days} day${days > 1 ? 's' : ''}`)
      return parts.join(' ')
    }
    if (remainingHours > 0) {
      parts.push(`${remainingHours}h`)
      return parts.join(' ')
    }
    if (remainingMinutes > 0) {
      parts.push(`${remainingMinutes}m`)
    }

    return parts.join(' ')
  }

  const displaySex = displaySexAcronym(sex)

  const visitLength = formatDuration(lengthOfStay)

  const visitStatus = () => {
    switch (outcome) {
      case 'admit':
        return endedAt ? 'Completed' : 'Admitted'
      case 'discharge':
        return endedAt ? 'Completed' : 'Discharged'
      case 'outcome':
        return endedAt ? 'Completed' : 'Outcome'
      case 'observation':
        return endedAt ? 'Completed' : 'Observation'
      case 'lwbs':
        return 'LWBS'
      case 'death':
        return 'Death'
      default:
        return null
    }
  }

  const handleCheckboxChange = async (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (!event.currentTarget.checked && completed && valid !== null) {
      const res = await fetch(`/care-api/incidental-findings/${id}/complete`, {
        method: 'POST',
        body: JSON.stringify({ completed: false, currentUser }),
      })
      if ((await res.json()).success) {
        openToast({
          title: 'Marked as incomplete',
          intent: 'success',
          message: `The alert for ${fullName} has been marked as incomplete`,
        })
      }
      return
    }

    navigate(`${visitId}/${id}`)
  }

  return (
    <ListViewItem
      className={twMerge(
        'grid-cols-[2rem_3rem_1fr_10rem] xl:grid-cols-[2rem_5rem_1fr_12rem]',
        'md:[&>*]:min-w-0',
        'hover:bg-dust',
        selected && 'md:relative md:bg-dust md:shadow-xl',
      )}
      data-testid={`visits:patient:${id}`}
    >
      <div>
        <CheckboxItem
          loading={false}
          checked={completed}
          aria-label="Completed"
          id={id}
          onChange={handleCheckboxChange}
        />
      </div>
      <p className="hyphens-auto text-xs text-storm leading-5 max-w-full font-semibold break-words uppercase">
        {currentRoom}
      </p>

      <div className="flex">
        <MaybeLink className="block w-full no-underline" to={{ pathname: `${visitId}/${id}` }}>
          <div className="flex flex-wrap items-baseline gap-x-2 gap-y-0 mb-1">
            <strong className="hyphens-auto break-words underline" data-dd-privacy="mask">
              {fullName}
            </strong>
            <Caption asChild={true}>
              <span>{[age, displaySex].filter(Boolean).join(', ')}</span>
            </Caption>
            {valid !== null &&
              (valid ? (
                <div className="flex items-baseline gap-0.5 text-xs font-semibold text-green-600">
                  <Icon path={mdiCheckCircleOutline} className="size-4 text-current relative -bottom-1" />
                  Valid
                </div>
              ) : (
                <div className="flex items-baseline gap-0.5 leading-none text-xs font-semibold text-red-600">
                  <Icon path={mdiCloseCircleOutline} className="size-4 text-current relative -bottom-1" />
                  Invalid
                </div>
              ))}
          </div>
          {findings && (
            <Caption asChild={true}>
              <div>{findings.join(', ')}</div>
            </Caption>
          )}
        </MaybeLink>
      </div>

      <div>
        <Stack spacing="quarter">
          {visitLength && (
            <Caption asChild={true} secondary={true} className="!first-letter:uppercase">
              <div data-dd-action-name="visit length of stay">
                {visitLength}{' '}
                {visitStatus() && (
                  <span className="ml-1 text-xs border rounded-md bg-slate-100 inline-block py-0 px-1">
                    {visitStatus()}
                  </span>
                )}
              </div>
            </Caption>
          )}

          {canReceiveMessages ? (
            <Link
              className="flex justify-end"
              title={`Send a message to ${fullName}`}
              data-testid="visits:direct-message"
              data-dd-action-name="send a message to patient button"
              to={{
                pathname: `${visitId}/messaging`,
              }}
            >
              <Icon path={mdiMessageProcessing} className="size-5" />
            </Link>
          ) : null}
        </Stack>
      </div>
    </ListViewItem>
  )
}

const IncidentalFindingsSubpage = () => {
  // trigger mocks
  const update = useMock()
  // @TODO - hook up loading / empty states
  const [_, setIsLoading] = useState(true)
  const [data, setData] = useState<IncidentalFinding | null>(null)

  const { incidentalFindingsId } = useParams()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetch(`/care-api/incidental-findings/${incidentalFindingsId}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setIsLoading(false)
      })
  }, [incidentalFindingsId, update])

  const openToast = useToast()
  const [invalidReasons, setInvalidReasons] = useState<string[]>([])
  const [otherReason, setOtherReason] = useState<string | null>(null)
  const [isOtherReasonOpen, setIsOtherReasonOpen] = useState(false)

  const reasonsDialogRef = useRef<HTMLDialogElement>(null)

  const phoneNumber = formatPhoneNumber(data?.phoneNumber)
  const assignedBed = data?.assignedBed || null
  const assignedRoom = data?.assignedRoom || null
  const currentBed = data?.currentBed || null
  const currentRoom = data?.currentRoom || null

  const name = data?.demographics ? formatName(data?.demographics) : null
  const age = data?.demographics?.dob ? differenceInYears(new Date(), new Date(data.demographics.dob)) : null
  const sex = data?.demographics?.sex ? displaySexAcronym(data?.demographics?.sex as Sex) : null

  const title = `${name} ${age || sex ? `(${age}${sex ? `, ${sex}` : ''})` : ''} `

  const currentUser = getValueFromLocalStorage<string>('email')

  const handleCloseReasonsDialog = () => {
    // reset the dialog state
    setInvalidReasons([])
    setOtherReason(null)
    setIsOtherReasonOpen(false)
    // close the dialog
    reasonsDialogRef.current?.close()
  }

  const handleSaveReasonsDialog = () => {
    // close dialog
    reasonsDialogRef.current?.close()
    // mutation
    fetch(`/care-api/incidental-findings/${incidentalFindingsId}/invalid`, {
      method: 'POST',
      body: JSON.stringify({
        currentUser,
        details: reasonsForInvalid
          .filter(({ value }) => invalidReasons.includes(value))
          .map(({ label }) => label)
          .concat(otherReason ? [otherReason] : []),
      }),
    })

    // reset the dialog state
    setInvalidReasons([])
    setOtherReason(null)
    setIsOtherReasonOpen(false)

    // toast
    openToast({
      title: 'Marked as invalid',
      intent: 'success',
      message: `The alert for ${name} has been marked as invalid`,
    })
  }

  // Handle the light dismiss of the dialog
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const reasonsDialog = reasonsDialogRef.current

    if (!reasonsDialog) {
      return
    }

    const handleLightDismiss = (event: MouseEvent) => {
      const rect = reasonsDialog.getBoundingClientRect()

      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width

      if (!isInDialog) {
        handleCloseReasonsDialog()
      }
    }

    reasonsDialog.addEventListener('click', handleLightDismiss)

    return () => {
      reasonsDialog.removeEventListener('click', handleLightDismiss)
    }
  }, [])

  return (
    <>
      <Drawer isVisible={true} loading={false} data-testid="visits:drawer-subpage" aria-label="Incidental findings">
        <Routes>
          <Route
            path="report"
            element={
              <>
                <DrawerHeader title={title} backTo=".." />
                <DrawerContent>
                  <div className="space-y-4">
                    <h2 className="text-base font-semibold">Full incidental findings report</h2>
                    {data?.diagnosticTestResult.split('\n').map((line, index) => (
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      <p key={index}>{line}</p>
                    ))}
                  </div>
                </DrawerContent>
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <DrawerHeader title={title} backTo="../.." />
                <DrawerContent>
                  <div className="space-y-4">
                    {typeof data?.valid === 'boolean' && (
                      <div className="space-y-4 border-b pb-4">
                        {data?.valid ? (
                          <>
                            <h2 className="text-base font-semibold flex">
                              Valid alert
                              <Icon path={mdiTextBoxCheck} className="size-6 text-green-700 ml-auto" />
                            </h2>
                            <p>{data?.findings.join(', ')}</p>
                          </>
                        ) : (
                          <>
                            <h2 className="text-base font-semibold flex">
                              Invalid alert
                              <Icon path={mdiTextBoxRemove} className="size-6 text-red-700 ml-auto" />
                            </h2>
                            <p>{data?.findings.join(', ')}</p>
                          </>
                        )}
                        <button
                          className="flex items-center gap-2 text-slate-700 font-semibold text-xs bg-slate-100 hover:bg-slate-200 hover:text-slate-800 rounded-full px-4 py-2"
                          type="button"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                `Phone Call Documentation

                                ${new Date().toLocaleString(undefined, {
                                  dateStyle: 'short',
                                  timeStyle: 'short',
                                })}

                                The patient was found to have the following incidental finding on imaging: ${data?.findings.join(', ')}.

                                I called and spoke with ${name} over the phone, discussed this finding with them, and instructed them to follow up 
                                with their primary care provider for further evaluation and management of this finding within 1 week. 
                                The patient expressed understanding of the clinical significance of the incidental finding and the importance of follow-up.`.trim(),
                              )
                              .then(() => {
                                openToast({
                                  title: 'Copied to clipboard',
                                  intent: 'success',
                                  message: `Finding summary for ${name} has been copied to clipboard`,
                                })
                              })
                          }}
                        >
                          <Icon path={mdiContentCopy} className="size-4" />
                          Copy to clipboard
                        </button>
                      </div>
                    )}

                    {!data?.completed && typeof data?.valid === 'boolean' && (
                      <div className="space-y-4 border-b pb-4">
                        <h2 className="text-base font-semibold">Next steps</h2>
                        <Button
                          iconBefore={mdiCheck}
                          block={true}
                          soft={true}
                          element="button"
                          onClick={() => {
                            fetch(`/care-api/incidental-findings/${incidentalFindingsId}/complete`, {
                              method: 'POST',
                              body: JSON.stringify({ completed: true, currentUser }),
                            })
                          }}
                        >
                          Mark as Complete
                        </Button>
                        <div className="flex gap-2 *flex-1">
                          {phoneNumber && (
                            <Button
                              iconBefore={mdiPhone}
                              block={true}
                              soft={true}
                              intent="neutral"
                              element="a"
                              href={`tel:${HIDE_CALLER_ID_CODE}${phoneNumber}`}
                            >
                              Call
                            </Button>
                          )}

                          {data?.canReceiveSms && (
                            <Button
                              iconBefore={mdiMessageProcessing}
                              block={true}
                              soft={true}
                              to={{
                                pathname: 'messaging',
                              }}
                              intent="neutral"
                              element="link"
                            >
                              Message
                            </Button>
                          )}
                        </div>
                      </div>
                    )}

                    {!data?.completed && typeof data?.valid !== 'boolean' && (
                      <div className="space-y-4 border-b pb-4">
                        <h2 className="text-base font-semibold">Is alert valid?</h2>
                        <p>{data?.findings.join(', ')}</p>
                        <div className="flex gap-2">
                          <Button
                            iconBefore={mdiTextBoxCheck}
                            block={true}
                            element="button"
                            onClick={() => {
                              // mutation
                              fetch(`/care-api/incidental-findings/${incidentalFindingsId}/valid`, {
                                method: 'POST',
                                body: JSON.stringify({ valid: true, currentUser }),
                              })
                            }}
                          >
                            Valid
                          </Button>
                          <Button
                            iconBefore={mdiTextBoxRemove}
                            intent="destructive"
                            soft={true}
                            block={true}
                            element="button"
                            onClick={() => {
                              if (reasonsDialogRef.current?.showModal) {
                                reasonsDialogRef.current?.showModal()
                              }
                            }}
                          >
                            Invalid
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="space-y-4 border-b">
                      <h2 className="text-base font-semibold">What happened?</h2>

                      <div className="space-y-4 overflow-y-auto max-h-[40vh] px-4 pb-4 -mx-4">
                        {(data?.history ?? []).map((event) => (
                          <div className="flex gap-4" key={event.id}>
                            {(() => {
                              switch (event.type) {
                                case 'valid':
                                  return (
                                    <Icon path={mdiTextBoxCheck} size={1} className="flex-none size-5 text-green-700" />
                                  )
                                case 'invalid':
                                  return (
                                    <Icon
                                      path={mdiTextBoxRemove}
                                      size={1}
                                      className="flex-none size-5 text-pohutukawa"
                                    />
                                  )
                                case 'incomplete':
                                  return <Icon path={mdiClose} size={1} className="flex-none size-5 text-pohutukawa" />
                                default:
                                  return <Icon path={mdiCheck} size={1} className="flex-none size-5 text-green-700" />
                              }
                            })()}
                            <div>
                              {event.title}
                              <ul className="list-disc list-inside text-sm">
                                {(event?.details ?? []).map((detail) => (
                                  <li key={detail}>{detail}</li>
                                ))}
                              </ul>
                              <small className="text-xs block mt-1">
                                {new Date(event.performedAt).toLocaleTimeString(undefined, { timeStyle: 'short' })} by{' '}
                                {event.performedBy}
                              </small>
                            </div>
                          </div>
                        ))}

                        <div className="flex gap-4">
                          <Icon path={mdiBell} size={1} className="size-5 text-slate-500" />
                          <div>
                            Alert sent
                            <small className="text-xs block mt-1">
                              {data?.createdAt
                                ? new Date(data?.createdAt).toLocaleTimeString(undefined, { timeStyle: 'short' })
                                : null}{' '}
                              by Vital AI
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* order */}
                    <div className="border-b space-y-4 pb-4">
                      <h2 className="text-base font-semibold">Order</h2>
                      <p className="line-clamp-5">{data?.diagnosticTestName}</p>
                    </div>
                    <div className="border-b space-y-4 pb-4">
                      <h2 className="text-base font-semibold">Reference</h2>
                      <p className="line-clamp-5">{data?.diagnosticTestResult}</p>
                      <Button
                        iconBefore={mdiFileDocumentOutline}
                        soft={true}
                        intent="neutral"
                        block={true}
                        element="link"
                        to={{ pathname: 'report' }}
                      >
                        See the full report
                      </Button>
                    </div>
                    <div className="space-y-4 border-b pb-4">
                      <h2 className="text-base font-semibold">Location</h2>
                      <VisitSubpageLocationTag
                        assignedBed={assignedBed}
                        assignedRoom={assignedRoom}
                        currentBed={currentBed}
                        currentRoom={currentRoom}
                      />
                    </div>

                    <div className="space-y-4">
                      <h2 className="text-base font-semibold">Phone number</h2>
                      {phoneNumber ? (
                        <Button
                          element="a"
                          href={`tel:${HIDE_CALLER_ID_CODE}${phoneNumber}`}
                          soft={true}
                          collapsed={true}
                          data-testid="visits:drawer-subpage-phone-number"
                          data-dd-action-name="patient phone number button"
                        >
                          {phoneNumber}
                        </Button>
                      ) : (
                        <p className="text-sm text-slate-500">&mdash;</p>
                      )}
                    </div>
                  </div>
                </DrawerContent>
              </>
            }
          />
        </Routes>
      </Drawer>
      <dialog className={twMerge(dialog, 'text-left pb-6')} ref={reasonsDialogRef}>
        <div className="sticky  top-0 flex-none min-h-fit grid grid-cols-[40px_1fr_40px] py-4 px-6 place-items-center bg-white -mx-4 -mt-4 mb-4 rounded-t-lg">
          <h2 id="dialog-title" className="text-md leading-8 font-semibold col-start-2">
            What did we get wrong?
          </h2>
          <button
            className="cols-start-3 p-2 rounded-full hover:bg-slate-200 transition-all "
            aria-label="Close"
            data-testid="dialog:button:close"
            type="button"
            id="close"
            onClick={() => {
              handleCloseReasonsDialog()
            }}
          >
            <Icon path={mdiClose} size={1} />
          </button>
        </div>
        <div className="space-y-2 px-4">
          {reasonsForInvalid.map((reason) => (
            <label key={reason.value} className="flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                name="reason"
                value={reason.value}
                checked={invalidReasons.includes(reason.value)}
                className="peer opacity-0 size-0"
                onChange={(e) => {
                  if (e.target.checked) {
                    setInvalidReasons((prev) => [...prev, e.target.value])
                  } else {
                    setInvalidReasons((prev) => prev.filter((reason) => reason !== e.target.value))
                  }
                }}
              />
              <Icon
                path={mdiCheckboxBlankOutline}
                className="peer-focus-visible:outline peer-focus-visible:outline-2 peer-checked:hidden size-8 text-gray-500"
              />
              <Icon
                path={mdiCheckboxMarked}
                className="peer-focus-visible:outline peer-focus-visible:outline-2 hidden peer-checked:block size-8 text-kereru"
              />
              {reason.label}
            </label>
          ))}
          <label className="flex items-center gap-2 cursor-pointer peer-other">
            <input
              type="checkbox"
              name="reason"
              value={'other'}
              className="peer opacity-0 size-0"
              checked={isOtherReasonOpen}
              onChange={(e) => {
                setIsOtherReasonOpen(e.target.checked)
              }}
            />
            <Icon
              path={mdiCheckboxBlankOutline}
              className="peer-focus-visible:outline peer-checked:hidden size-8 text-gray-500"
            />
            <Icon
              path={mdiCheckboxMarked}
              className="peer-focus-visible:outline hidden peer-checked:block size-8 text-kereru"
            />
            Other (please specificy)
          </label>
          <textarea
            maxLength={200}
            rows={3}
            value={otherReason ?? ''}
            className="[label.peer-other:has(input:checked)~&]:block hidden w-full border-2 border-gray-300 rounded-md p-2"
            onChange={(e) => {
              setOtherReason(e.target.value)
            }}
          />
          <footer className="pt-4">
            <Button
              element="button"
              iconBefore={mdiCheck}
              block={true}
              intent="positive"
              onClick={() => {
                handleSaveReasonsDialog()
              }}
            >
              Submit
            </Button>
          </footer>
        </div>
      </dialog>
      <Outlet />
    </>
  )
}

const IncidentalFindings: FC = () => {
  const update = useMock()
  const [items, setItems] = useState<IncidentalFinding[]>([])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchItems = async () => {
      const response = await fetch('/care-api/incidental-findings')
      const data = (await response.json()) as { data: IncidentalFinding[] | undefined }

      setItems(data.data ?? [])
    }
    fetchItems()
  }, [update])

  useEffect(() => {
    document.title = 'Patients | Vital CareAssist'
  }, [])

  const [showCompleted, setShowCompleted] = useState(false)
  const [showValidated, setShowValidated] = useState(true)
  const handleCompletedOnClick = () => {
    setShowCompleted(!showCompleted)
  }
  const handleValidatedOnClick = () => {
    setShowValidated(!showValidated)
  }

  const isSubpage = useMatch('/:facilityId/incidental-findings/:visitId/:incidentalFindingsId')

  // uuid
  const visitsError = false
  const visitsLoading = false
  const filtersLoading = false

  const { sortByColumn, sortDirection, onSort } = useSortColumn(false)

  const inboxItems = items.filter((item) => !item.completed && item.valid === null)
  const validatedItems = items.filter((item) => item.valid !== null && !item.completed)
  const completedItems = items.filter((item) => item.completed)

  const currentUser = getValueFromLocalStorage<string>('email')

  const sort = (a: (typeof data)[0], b: (typeof data)[0]) => {
    let compare = 0

    switch (sortByColumn) {
      case 'patient':
        compare = (a.demographics?.lastName ?? '').localeCompare(b.demographics?.lastName ?? '')
        break
      case 'location':
        compare = (a.currentRoom ?? '').localeCompare(b.currentRoom ?? '')
        break
      case 'timestamp':
        compare = (a.lengthOfStay ?? 0) - (b.lengthOfStay ?? 0)
        break
      default:
        return 0
    }
    return sortDirection === 'ascending' ? compare : -compare
  }

  inboxItems.sort(sort)
  validatedItems.sort(sort)
  completedItems.sort(sort)

  return (
    <div className={twMerge('w-full', isSubpage && 'md:pr-[320px] xl:pr-[450px]')}>
      <Constrain size="kiwi" className={'max-w-6xl'}>
        <Stack spacing={1}>
          <Card data-testid="patient-list">
            <ListView>
              <NotificationsViewHeader onSort={onSort} sortDirection={sortDirection} sortByColumn={sortByColumn} />

              {(filtersLoading || visitsLoading) && <VisitsListLoading />}

              {visitsError && !visitsLoading && !filtersLoading && <VisitsListConnectionError />}

              {!visitsError && !visitsLoading && !filtersLoading && (
                <>
                  {inboxItems.map((item) => (
                    <ItemRow
                      key={item.id}
                      id={item.id}
                      visitId={item.visitId}
                      findings={item.findings}
                      canReceiveMessages={item.canReceiveSms}
                      fullName={formatName(item.demographics)}
                      age={differenceInYears(new Date(), new Date(item.demographics?.dob ?? ''))}
                      sex={item.demographics?.sex}
                      selected={false}
                      lengthOfStay={item.lengthOfStay ?? 0}
                      currentRoom={item.currentRoom}
                      valid={item.valid}
                      completed={item.completed}
                      currentUser={currentUser ?? ''}
                      outcome={item.outcome}
                      endedAt={item.endedAt}
                    />
                  ))}

                  <Button
                    collapsed={true}
                    element="button"
                    size="piwakawaka"
                    block={true}
                    intent="neutral"
                    iconAfter={showValidated ? mdiMenuUp : mdiMenuDown}
                    className="!justify-between rounded-none !bg-dust !p-4 !uppercase !no-underline"
                    onClick={handleValidatedOnClick}
                    data-dd-action-name="toggle completed notifications tab"
                  >
                    Follow-up needed ({validatedItems.length})
                  </Button>
                  {showValidated &&
                    validatedItems.map((item) => {
                      return (
                        <ItemRow
                          key={item.id}
                          id={item.id}
                          visitId={item.visitId}
                          findings={item.findings}
                          canReceiveMessages={item.canReceiveSms}
                          fullName={formatName(item.demographics)}
                          age={differenceInYears(new Date(), new Date(item.demographics?.dob ?? ''))}
                          sex={item.demographics?.sex}
                          selected={false}
                          lengthOfStay={item.lengthOfStay ?? 0}
                          currentRoom={item.currentRoom}
                          outcome={item.outcome}
                          endedAt={item.endedAt}
                          valid={item.valid}
                          completed={item.completed}
                          currentUser={currentUser}
                        />
                      )
                    })}

                  <Button
                    collapsed={true}
                    element="button"
                    size="piwakawaka"
                    block={true}
                    intent="neutral"
                    iconAfter={showCompleted ? mdiMenuUp : mdiMenuDown}
                    className="!justify-between rounded-none !bg-dust !p-4 !uppercase !no-underline"
                    onClick={handleCompletedOnClick}
                    data-dd-action-name="toggle completed notifications tab"
                  >
                    Completed ({completedItems.length})
                  </Button>
                  {showCompleted &&
                    completedItems.map((item) => {
                      return (
                        <ItemRow
                          key={item.id}
                          id={item.id}
                          visitId={item.visitId}
                          findings={item.findings}
                          canReceiveMessages={item.canReceiveSms}
                          fullName={formatName(item.demographics)}
                          age={differenceInYears(new Date(), new Date(item.demographics?.dob ?? ''))}
                          sex={item.demographics?.sex}
                          selected={false}
                          lengthOfStay={item.lengthOfStay ?? 0}
                          currentRoom={item.currentRoom}
                          outcome={item.outcome}
                          endedAt={item.endedAt}
                          valid={item.valid}
                          completed={item.completed}
                          currentUser={currentUser}
                        />
                      )
                    })}
                </>
              )}
            </ListView>
          </Card>
        </Stack>
        <Routes>
          <Route path={':visitId/:incidentalFindingsId/*'} element={<IncidentalFindingsSubpage />}>
            {/* keep sidepanel open while sending message */}
            <Route path={'messaging'} element={<PatientCommunication />} />
          </Route>
          {/* just open message dialog with no sidepanel - quick message button on row */}
          <Route path={':visitId/messaging'} element={<PatientCommunication />} />
        </Routes>
      </Constrain>
    </div>
  )
}

export default IncidentalFindings
