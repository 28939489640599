import { gql } from '@apollo/client'

const VISIT_BY_ID_FOR_FACILIITY_ID_QUERY = gql`
  query VisitByIdForFacilityId($input: VisitByIdForFacilityIdInput!) {
    visitByIdForFacilityId(input: $input) {
      id
      externalAccountId
      loggedInAt
      disabled
      disabledByClinician
      primaryPhoneNumber
      erVisitStartedAt
      inpatientVisitStartedAt
      demographics {
        firstName
        lastName
        dob
        sex
        mobileNumber
        homeNumber
        externalPatientId
        externalPatientIdType
      }
      reasonForVisit
      currentLocation {
        location {
          bed
          room
        }
      }
      assignedBed {
        location {
          bed
          room
        }
      }
      serviceRequests {
        label
        notificationGroupId
        requestId
      }
      serviceRequestEnabled
      er {
        nextStep {
          key
          isAutomatic
          estimates {
            startedAt
            minCompletedAt
            maxCompletedAt
            capInMs
          }
        }
        steps {
          key
          status
          completedAt
        }
      }
    }
  }
`

export default VISIT_BY_ID_FOR_FACILIITY_ID_QUERY
