import { mdiAlertCircle, mdiCheck, mdiInformation } from '@mdi/js'
import type { ComponentPropsWithoutRef, FC, ReactNode } from 'react'
import type { Colour } from '../../types'
import { Icon } from '../icon/icon'

export interface AlertProps extends ComponentPropsWithoutRef<'div'> {
  children?: ReactNode
  intent?: 'info' | 'success' | 'error'
}

export const Alert: FC<AlertProps> = ({ children, className = '', intent = 'info', ...htmlElementAttributes }) => {
  let borderClass: string
  let icon: string
  let textClass: string
  let colour: Colour

  switch (intent) {
    case 'error':
      colour = 'pohutukawa'
      borderClass = 'border-pohutukawa'
      textClass = 'text-pohutukawa'
      icon = mdiAlertCircle
      break

    case 'success':
      colour = 'kiwi'
      borderClass = 'border-kiwi'
      textClass = 'text-kiwi'
      icon = mdiCheck
      break

    case 'info':
      colour = 'kereru'
      borderClass = 'border-kereru'
      textClass = 'text-kereru'
      icon = mdiInformation
      break
  }

  return (
    <div
      className={`flex gap-2 rounded border p-2 ${borderClass} ${textClass} ${className}`}
      {...htmlElementAttributes}
    >
      <Icon path={icon} size={3} colour={colour} />
      {children}
    </div>
  )
}
