import type { FC, HTMLProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { CardLoader } from './card-loader'

export interface CardProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode
  'data-testid'?: string
  actionName?: string
  loading?: boolean
  naked?: boolean
}

export const Card: FC<CardProps> = ({
  className,
  'data-testid': dataTestId,
  actionName,
  loading = false,
  naked = false,
  children,
}) => {
  return (
    <div
      data-loading={loading}
      data-testid={dataTestId || 'component:card'}
      data-dd-action-name={actionName}
      className={twMerge(naked ? '' : 'bg-white sm:rounded-md sm:border', className)}
    >
      {loading && <CardLoader />}
      {!loading && children}
    </div>
  )
}
