import type { FC, ReactNode } from 'react'

interface DrawerContentProps {
  children?: ReactNode
}

export const DrawerContent: FC<DrawerContentProps> = ({ children }) => {
  return (
    <div
      data-testid="component:drawer:content"
      className="shrink grow basis-0 bg-[var(--colour-background)] p-[var(--gutter-padding)]"
    >
      {children}
    </div>
  )
}
